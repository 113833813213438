<template>
  <div>
    <!-- 第一个页面存放项目和企业数据 -->
    <div v-if="switc == 1">
      <el-card>
        <el-form :inline="true" class="demo-form-inline">

          <el-form-item label="项目名称:">
            <el-input placeholder="请输入项目名称" v-model="pro_name"></el-input>
          </el-form-item>

          <el-form-item label="系统评分:">
            <el-select v-model="num" placeholder="全部">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="top">
          <ol>
            <li><span></span></li>
            <li>
              <a href="http://www.aqjkpt.com:9999/266049f3-6af4-4f46-9c79-1e2ebc3c23fd.docx"
                class="download">双重预评估企业标准表下载</a>
              <el-button type="success" size="mini" @click="exportExcel" v-if="but == 1">导出报表</el-button>
              <el-button type="success" size="mini" v-if="but2 == 1" @click="exportExcel2">导出报表</el-button>
            </li>
          </ol>
        </div>
        <!-- //标签页 -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="项目" name="1">
            <!-- //表格 -->
            <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{
              background: '#4d7cfe',
              color: '#ffffff',
              textAlign: 'center',
            }">
              <el-table-column prop="name" label="项目名称" align="center">
              </el-table-column>
              <el-table-column prop="corporations" label="所属公司" align="center">
              </el-table-column>
              <el-table-column prop="engineering" label="工程性质" align="center">
              </el-table-column>
              <el-table-column prop="site" label="所属区域" align="center">
              </el-table-column>
              <el-table-column prop="system_evaluation" label="系统综合评估" align="center">
              </el-table-column>
              <el-table-column prop="update_time" label="最后登录时间" align="center">
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-link type="primary" :underline="false" @click="query(scope.row)">查看详情</el-link>
                </template>
              </el-table-column>
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[2, 5, 10, 20]" :page-size="pagesize" :total="total">
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="企业" name="2" v-if="rank">
            <!-- day2 -->
            <el-table :data="tableData3" stripe style="width: 100%" :header-cell-style="{
              background: '#4d7cfe',
              color: '#ffffff',
              textAlign: 'center',
            }">
              <el-table-column prop="name" label="企业名称" align="center">
              </el-table-column>
              <el-table-column prop="business" label="企业资质" align="center">
              </el-table-column>
              <el-table-column prop="address" label="注册地址" align="center">
              </el-table-column>
              <el-table-column prop="pro_num" label="旗下项目数量" align="center">
              </el-table-column>
              <el-table-column prop="fir_evaluation" label="系统综合评估" align="center">
              </el-table-column>
              <el-table-column prop="update_time" label="最后登录时间" align="center">
              </el-table-column>
              <el-table-column prop="" label="操作" align="center">
                <template slot-scope="scope">
                  <el-link type="primary" :underline="false" @click="enterprisequery(scope.row)">查看详情</el-link>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="page1"
              :page-sizes="[2, 5, 10, 20]" :page-size="pagesize1" :total="total1">
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <!-- 第二个页面存放项目和企业数据的点击查看详情页面 -->
    <div v-else-if="switc == 2">
      <!-- 项目的查看详情 -->
      <el-card v-if="floa">
        <el-button type="primary" size="mini" @click="previous" round>返回上一级</el-button>
        <el-card>
          <div class="left">
            <p>
              项目名称 :<span>{{ top.name }}</span>
            </p>
            <p>
              所属公司 :<span>{{ top.corporations }}</span>
            </p>
            <p>
              所属区域 : <span>{{ top.site }}</span>
            </p>
            <p>
              工程性质 :<span>{{ top.engineering }}</span>
            </p>
            <p>
              最后更新时间 :<span>{{ top.update_time }}</span>
            </p>
          </div>
          <div class="right">
            <div class="father">
              <p>综合评分</p>
              <div class="round">
                <span>{{ top.system_evaluation }}</span>
              </div>
            </div>
          </div>
        </el-card>
        <!-- //表单 -->
        <el-table :data="tableData2" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
          <el-table-column prop="number" label="序号" width="100px">
          </el-table-column>
          <el-table-column prop="evaluation_item" label="评估项目" width="100px">
          </el-table-column>
          <el-table-column label="体系建设标准与要求" width="600">
            <template slot-scope="scope">
              <div>{{ scope.row.sys_standards_requirements.one }}</div>
              <div>{{ scope.row.sys_standards_requirements.two }}</div>
              <div>{{ scope.row.sys_standards_requirements.three }}</div>
              <div>{{ scope.row.sys_standards_requirements.four }}</div>
              <div>{{ scope.row.sys_standards_requirements.five }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="evaluation_content" label="评估内容">
            <template slot-scope="scope">
              <div>{{ scope.row.evaluation_content.one }}</div>
              <div>{{ scope.row.evaluation_content.two }}</div>
              <div>{{ scope.row.evaluation_content.three }}</div>
              <div>{{ scope.row.evaluation_content.five }}</div>
              <div>{{ scope.row.evaluation_content.four }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- //企业的查看详情 -->
      <el-card v-else>
        <el-button type="primary" size="mini" @click="previous" round>返回上一级</el-button>
        <el-card>
          <div class="left">
            <p>
              企业名称 : <span>{{ enterprise_top.name }}</span>
            </p>
            <p>
              旗下项目数量 : <span>{{ enterprise_top.pro_num }}</span>
            </p>
            <p>
              企业资质 : <span>{{ enterprise_top.business }}</span>
            </p>
            <p>
              所属区域 : <span>{{ enterprise_top.address }}</span>
            </p>
            <p>
              最后更新时间 : <span>{{ enterprise_top.update_time }}</span>
            </p>
          </div>
          <div class="right">
            <div class="father">
              <p>综合评分</p>
              <div class="round">
                <span>{{ enterprise_top.fir_evaluation }}</span>
              </div>
            </div>
          </div>
        </el-card>
        <!-- //表单 -->
        <el-table :data="tableData2" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
          <el-table-column prop="number" label="序号" width="100px">
          </el-table-column>
          <el-table-column prop="evaluation_item" label="评估项目" width="100px">
          </el-table-column>
          <el-table-column label="体系建设标准与要求" width="600">
            <template slot-scope="scope">
              <div>{{ scope.row.sys_standards_requirements.one }}</div>
              <div>{{ scope.row.sys_standards_requirements.two }}</div>
              <div>{{ scope.row.sys_standards_requirements.three }}</div>
              <div>{{ scope.row.sys_standards_requirements.four }}</div>
              <div>{{ scope.row.sys_standards_requirements.five }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="evaluation_content" label="评估内容">
            <template slot-scope="scope">
              <div>{{ scope.row.evaluation_content.one }}</div>
              <div>{{ scope.row.evaluation_content.two }}</div>
              <div>{{ scope.row.evaluation_content.three }}</div>
              <div>{{ scope.row.evaluation_content.five }}</div>
              <div>{{ scope.row.evaluation_content.four }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>
import {
  addassessment,
  addassessmentday2,
  addassessmententerprise,
  addassessmententerprises
} from "../../../request/assessment";
export default {
  name: "",
  props: [],
  data() {
    return {
      rank: '',
      tableData: [],
      tableData2: [],
      tableData3: [],
      activeName: "1",
      switc: 1, //表格切换
      page: 1,
      pagesize: 10,
      page1: 1,
      pagesize1: 10,
      page2: 1,
      pagesize2: 5,
      total: 0,
      total1: 0,
      options: [
        {
          value: "全部",
          label: "全部",
        },
        {
          value: "0",
          label: "分数从高到低",
        },
        {
          value: "1",
          label: "分数从低到高",
        },
      ],
      options1: [
        {
          value: "100",
          label: "全部",
        },
        {
          value: "70",
          label: "70分以下",
        },
        {
          value: "60",
          label: "60分以下",
        },
        {
          value: "50",
          label: "50分以下",
        },
        {
          value: "40",
          label: "40分以下",
        },
        {
          value: "30",
          label: "30分以下",
        },
        {
          value: "20",
          label: "20分以下",
        },
      ],
      pro_name: "", //项目名
      name: "", //企业名
      num: "", //查询多少分,
      lang: "", //排序
      top: [],
      type_num: "", //项目id
      floa: true, //项目和企业的查看详情切换
      enterprise_top: [], //企业
      but: 1, //导出按钮显示或隐藏
      but2: 2, //导出按钮显示或隐藏
      pjtid: '',

    };
  },

  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.switc = 1;
      this.rank = localStorage.getItem("rank") == 0 || localStorage.getItem("rank") == 1 ? true : false
      this.shuju();
    },
  },

  methods: {
    // 项目
    async shuju() {
      this.but = 1;
      this.but2 = 2;
      let msg = {
        page: this.page,
        size: this.pagesize,
        name: this.pro_name,
        num: this.num,
        unique_identification: localStorage.getItem("uid"),

      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      const res = await addassessment(msg);
      //  console.log(res,159)
      this.total = res.data.count;
      this.tableData = res.data.data;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].update_time) {
          this.tableData[i].update_time = this.$moment(this.tableData[i].update_time).format("YYYY-MM-DD HH:mm:ss");
        }

      }
      // console.log(this.tableData, 159);
    },
    // 企业
    async shuju2() {
      let msg = {
        page: this.page1,
        size: this.pagesize1,
        pro_name: this.pro_name,
        name: localStorage.getItem('project') + localStorage.getItem('type'),
        num: this.num,
      };
      const res = await addassessmententerprise(msg);
      // console.log(res, 256);
      this.tableData3 = res.data.data;
      this.total1 = res.data.count;
    },
    // 企业
    async shuju3() {
      let msg = {
        page: this.page1,
        size: this.pagesize1,
        pro_name: this.pro_name,
        num: this.num,
        unique_identification: localStorage.getItem("uid"),

      };
      const res = await addassessmententerprises(msg);
      // console.log(res, 256);
      this.tableData3 = res.data.data;
      this.total1 = res.data.count;
    },


    //分页
    handleSizeChange(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize = pagesize;
      this.shuju();
    },
    handleCurrentChange(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.shuju();
    },
    //分页
    handleSizeChange1(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize1 = pagesize;

      if (localStorage.getItem('rank') == '0') {
        this.shuju3();

      } else {
        this.shuju2();

      }
    },
    handleCurrentChange1(page) {
      // console.log(`当前页: ${page}`);
      this.page1 = page;
      if (localStorage.getItem('rank') == '0') {
        this.shuju3();
      } else {
        this.shuju2();

      }
    },
    //查询
    onSubmit() {
      this.page = 1
      this.page1 = 1
      if (this.activeName == '1') {
        this.shuju();
      } else {
        if (localStorage.getItem('rank') == '0') {
          this.shuju3();
        } else {
          this.shuju2();
        }
      }

    },
    //重置
    reset() {
      this.num = "";
      this.pro_name = "";
      if (this.activeName == '1') {
        this.shuju();
      } else {
        if (localStorage.getItem('rank') == '0') {
          this.shuju3();
        } else {
          this.shuju2();
        }
      }
    },
    //点击查看详情
    async query(row) {
      this.type_num = row.id;
      this.top = row;
      this.top.update_time = this.$moment(this.top.update_time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.switc = 2;
      let msg = {
        type_num: row.id,
      };
      const res = await addassessmentday2(msg);
      this.tableData2 = res.data.data;
      if (this.tableData2.length > 0) {
        for (let i = 0; i < this.tableData2.length; i++) {
          this.tableData2[i].res1 = this.tableData2[i].evaluation_content.one;
        }
      }
      console.log(this.tableData2);
    },
    //点击返回上一级
    previous() {
      this.switc = 1;
      this.shuju();
    },

    async handleClick(tab, event) {
      this.pro_name = ''
      this.num = ''
      if (this.activeName == 1) {
        this.shuju();
        this.floa = true;
      }
      if (this.activeName == 2) {
        this.but2 = 1;
        this.but = 2;
        // this.shuju2();

        if (localStorage.getItem('rank') == '0') {
          this.shuju3();
        } else {
          this.shuju2();
        }
        this.floa = false;
      }
    },
    // 企业点击查看详情
    enterprisequery(row) {
      this.switc = 2;
      this.enterprise_top = row;
    },

    //导出报表项目
    exportExcel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../../vendor/Export2Excel"); //引入文件
        const tHeader = ["项目名称", "所属公司", "工程性质", "所属区域", "系统综合评估", "最后登录时间",]; //将对应的属性名转换成中文 //const tHeader = [];
        const filterVal = ["name", "corporations", "engineering", "site", "system_evaluation", "update_time",]; //table表格中对应的属性名
        console.log(this.tableData,'1111111111111111');

        const list = this.tableData; //想要导出的数据
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "列表excel");
      });
    },

    //导出报表企业
    exportExcel2() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("../../../vendor/Export2Excel"); //引入文件
        const tHeader = ["企业名称", "企业资质", "注册地址", "旗下项目数量", "系统综合评估", "最后登录时间",]; //将对应的属性名转换成中文 //const tHeader = [];
        const filterVal = ["name", "business", "address", "pro_num", "fir_evaluation", "update_time",]; //table表格中对应的属性名
        console.log(this.tableData3,'1111111111111111');
        const list = this.tableData3; //想要导出的数据
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "列表excel");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

  },
  mounted() {
    this.rank = localStorage.getItem("rank") == 0 || localStorage.getItem("rank") == 1 ? true : false
    this.shuju();
  },
  components: {},
  computed: {},
};
</script>

<style lang='less' scoped>
* {
  list-style: none;
}

.top {
  display: flex;
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.top ol>li {
  float: left;
}

.top ol>li:nth-child(1) {
  color: red;
}

.top ol>li:nth-child(2) {
  position: absolute;
  right: 10px;
}

.left {
  // display: -webkit-flex;
  // display: flex;
  width: 50%;
  float: left;
}

.left p {
  margin: 20px 20px;
  color: red;
}

.left p span {
  color: black;
  font-size: 14px;
  margin-left: 10px;
}

.right {
  // display: flex;
  width: 50%;
  height: 200px;
  // background-color: blue;
  float: left;
  position: relative;
}

.father {
  width: 100px;
  height: 100px;
  // background-color: blue;
  text-align: center;
  position: absolute;
  right: 50px;
  top: 15%;
}

.father p {
  margin-bottom: 10px;
}

.round {
  width: 100px;
  height: 100px;
  background-color: red;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
}

.round span {
  font-size: 35px;
  color: white;
}

.download {
  text-decoration: none;
  padding: 1px 10px;
  background-color: #67c23a;
  color: white;
  float: left;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 5px;
}
</style>
