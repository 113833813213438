import { render, staticRenderFns } from "./SystemAssessment.vue?vue&type=template&id=406c9797&scoped=true&"
import script from "./SystemAssessment.vue?vue&type=script&lang=js&"
export * from "./SystemAssessment.vue?vue&type=script&lang=js&"
import style0 from "./SystemAssessment.vue?vue&type=style&index=0&id=406c9797&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "406c9797",
  null
  
)

export default component.exports